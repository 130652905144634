.loginDesktopContainer {
    background-image: url('../../../public/CookingLoginPageDesktop.jpg');
    background-size: cover;
    background-position: center;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .formContainer {
    position: absolute;
    top: 40%;
    left: 10%;
    width: 500px;
    border: 1px solid #ffffffc4;
    border-radius: 10px;
  }