.appContainer {
  display: flex;
}

// .navContainer {
// z-index: 20;
//     width: 350px;
//     background-color: #ffffff;
//     height: 100vh;
//     box-shadow: -8px 0px 8px -8px rgb(0 0 0 / 31%) inset; 

//   & >div{
//     display: flex;
//     flex-direction: column;
//     padding: 15px;

//     & a{
//       text-decoration: unset;
//       color: black;
//       font-size: larger;
//       padding: 5px;
//     }

//     & a:hover{
//       color: #ff0707;
//     }
//   }
// }

.bodyContainer {
  width: 100%;
  background-color: aliceblue;
  height: 95vh;
  max-height: 95vh;
  overflow: auto;
  display: flex; 
}

.footerContainer{
  position: fixed;
  margin-left: 315px;
  bottom: 0;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .appContainer {
    display: flex;
    flex-direction: column;
  }

  .navContainer {
    z-index: 20;
    background-color: #ffffff;
    box-shadow: 1px -4px 12px -7px rgb(0 0 0 / 31%) inset;
    height: 100%;
    width: 100%;
    
      & >div{
        display: flex;
        flex-direction: column;
        padding: 15px;
    
        & a{
          text-decoration: unset;
          color: black;
          font-size: larger;
          padding: 5px;
        }
    
        & a:hover{
          color: #ff0707;
        }
      }
    }
    
    .bodyContainer {
      width: 100%;
      background-color: aliceblue;
      height: 95vh;
      max-height: 95vh;
      overflow: auto;
      display: flex; 
    }
    
    .footerContainer{
    bottom: 0;
    padding: 10px;
    position: unset;
    margin: unset;
    }
}