.navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color:black;
  padding: 10px;
  height: 5vh;
}

.link {
  text-decoration: none;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px;
}

.link:hover {
  color: #fefe61;
}
